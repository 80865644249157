.navigation {
  margin-top: emRhythm(4);
  margin-right: 1em;

  @include breakpoint($tablet) {
    text-align: center;
  }

  ul {
    margin-bottom: 0;

    @include breakpoint($mobile) {
      opacity: 0;
      visibility: hidden;

      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 9999;
      background-color: rgba($white, 1);
      margin: 0;
      padding-top: 10%;

      &.open {
        transition: opacity 0.5s, visibility 0.5s;
        opacity: 1;
        visibility: visible;
      }

      li {
        width: 100%;
        margin: 20px 0;
        text-align: center;

        &.is-acitve {
          border-bottom: none !important;
        }
      }
    }

    li {
      display: inline-block;
      margin-right: 2em;

      &.is-acitve {
        border-bottom: solid 2px white;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        font-family: 'walsheim-medium';
        letter-spacing: 1px;
        // @include setType(1*4, 1.2em);
        text-transform: uppercase;
        text-decoration: none;
        color: $red;

        &.is-acitve {
          // border-bottom: solid 2px $white;
        }
      } //a
    } //li
  } //ul

  .navigation-toggle {
    $width: 35px;
    $distance: 10px;
    $stroke: 3px;
    $time: 0.3s;
    $easing: $ease-out-back;

    float: right;
    display: none;
    z-index: 99999;
    position: relative;

    cursor: pointer;
    // padding: $distance * 2 0;
    padding-top: emRhythm(1);

    // background-color: red;
    height: 3 * $stroke + 2 * $distance;
    width: $width;

    @include breakpoint($mobile) {
      display: block;
    }

    span,
    span:before,
    span:after {
      cursor: pointer;
      height: $stroke;
      width: $width;
      background: $red;
      position: absolute;
      display: block;
      content: '';

      @include transition(
        transform $time $easing,
        top $time $easing,
        bottom $time $easing,
        width $time $easing
      );
    }

    span:before {
      top: -$distance;
    }

    span:after {
      bottom: -$distance;
    }

    &.open {

      span {
        background-color: transparent;
        width: 0;
      }

      span:before {
        top: 0;
      }

      span:after {
        bottom: 0;
      }

      span:before {
        transform: rotate(45deg);
      }

      span:after {
        transform: rotate(-45deg);
      }
    }
  } //.navigation-toggle

} //ul.navigation
