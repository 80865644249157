.logo {
  margin-top: emRhythm(4);
  float: left;
  margin-left: 1em;
  @include breakpoint($screen) { margin-left: 0em; }

  img {
    margin-top: emRhythm(2);
    height: emRhythm(6);
  }
}
