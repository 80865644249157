.block {
  $colors: (
    green: $lightgray,
    lightbrown: $lightgray,
    violet: $lightgray    
  );

  margin-bottom: emRhythm(1*3);
  padding: emRhythm(2);

  // color: $white;

  a {
    // color: $white;
    // text-decoration: underline;
  }

  ul { margin-left: 0; }
  li { list-style-type: none; }

  &.block-link,
  &.block-news {
    position: relative;
    height: emRhythm(8*4);

    @include transition(
      transform .3s $ease-out-back,
      box-shadow .3s $ease-out-back
    );

    @include breakpoint($screen) {
      &:hover {
        @include transform(
          translateY(emRhythm(-1))
          translateX(emRhythm(-1))
        );
        box-shadow: emRhythm(2) emRhythm(2) 0px $darkgray;
      }
    }
  }

  &.block-side {
    background-color: $lightgray;

    h2 {
      color: $black;
    }

    li {
      background-image: url("../img/icons/icon-download@2x.png");
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: 0px 4px;
      padding-left: 1.4em;
    }

    a {
      border-bottom: solid 1px rgba(0,0,0,0);
      // color: $white;

      @include transition(
        border-bottom .1s ease
      );

      &:hover {
        border-bottom: solid 1px $red;
      }
    }

    @each $color in lightbrown, violet {
      &.block-side-#{$color} {
        background-color: map-get($colors, $color);
      }
    }
  } //&.block-side

  &.block-news {
    background-color: $gray;
    display: table;

    h2 {
      display: table-cell;
      vertical-align: bottom;      
      position: relative;
      @include setType(1*4, 1.25em);
      z-index: 100;
      color: $white;
      text-shadow: 1px 1px rgba(0,0,0,0.8);
    }

    h4 {
      display: none;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      z-index: 0;
      filter: brightness(80%);
    }  

    @each $color in green, lightbrown, violet {
      &.block-news-#{$color} {
        // background-color: map-get($colors, $color);
      }
    }
  } //&.block-news

  &.block-link {
    overflow: hidden;
    background-color: $lightgray;

    h2 {
      // color: $black;      
      text-align: center;
      // position: absolute;
      bottom: 0;
      margin-top: emRhythm(1);
      @include setType(1*4, 1.25em);
    }

    img {
      position: relative;
      display: block;
      margin: 12px auto;
      height: 96px;
      width: 96px;
    }
  } //&.block-link
} //.block
