@import "../components/reset-css/reset.css";
@import "../components/susy/sass/susy";
@import "../components/breakpoint-sass/stylesheets/breakpoint";
@import "../components/bourbon/app/assets/stylesheets/bourbon";

@import "variables";
@import "base";
@import "fontface";

@import "../components/flickity/dist/flickity.min.css";
@import "../components/typesettings/typesettings";

@import "typography";

body {
  // @include debug-vertical-alignment();
}

$footer-height-mobile: emRhythm(23*4);
$footer-height-tablet: emRhythm(16*4);
$footer-height-screen: emRhythm(16*4);

@import "layout/page";
@import "layout/header";
@import "layout/teaser";
@import "layout/title";
@import "layout/content";
@import "layout/footer";

@import "module/logo";
@import "module/navigation";
@import "module/blocks";
@import "module/back";
@import "module/tabs";
@import "module/termine";

@import "print";
