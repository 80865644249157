@include font-face("walsheim-ultra-light", "../fonts/original/gt-walsheim-ultra-light-web", normal, $file-formats: eot woff ttf svg);
@include font-face("walsheim-ultra-light-oblique", "../fonts/original/gt-walsheim-ultra-light-oblique-web", normal, $file-formats: eot woff ttf svg);

@include font-face("walsheim-thin", "../fonts/original/gt-walsheim-thin-web", normal, $file-formats: eot woff ttf svg);
@include font-face("walsheim-thin-oblique", "../fonts/original/gt-walsheim-thin-oblique-web", normal, $file-formats: eot woff ttf svg);

@include font-face("walsheim-light", "../fonts/original/gt-walsheim-light-web", normal, $file-formats: eot woff ttf svg);
@include font-face("walsheim-light-oblique", "../fonts/original/gt-walsheim-light-oblique-web", normal, $file-formats: eot woff ttf svg);

@include font-face("walsheim-regular", "../fonts/original/gt-walsheim-regular-web", normal, $file-formats: eot woff ttf svg);
@include font-face("walsheim-regular-oblique", "../fonts/original/gt-walsheim-regular-oblique-web", normal, $file-formats: eot woff ttf svg);

@include font-face("walsheim-medium", "../fonts/original/gt-walsheim-medium-web", normal, $file-formats: eot woff ttf svg);
@include font-face("walsheim-medium-oblique", "../fonts/original/gt-walsheim-medium-oblique-web", normal, $file-formats: eot woff ttf svg);

@include font-face("walsheim-bold", "../fonts/original/gt-walsheim-bold-web", normal, $file-formats: eot woff ttf svg);
@include font-face("walsheim-bold-oblique", "../fonts/original/gt-walsheim-bold-oblique-web", normal, $file-formats: eot woff ttf svg);

@include font-face("walsheim-black", "../fonts/original/gt-walsheim-black-web", normal, $file-formats: eot woff ttf svg);
@include font-face("walsheim-black-oblique", "../fonts/original/gt-walsheim-black-oblique-web", normal, $file-formats: eot woff ttf svg);

@include font-face("walsheim-ultra-bold", "../fonts/original/gt-walsheim-ultra-bold-web", normal, $file-formats: eot woff ttf svg);
@include font-face("walsheim-ultra-bold-oblique", "../fonts/original/gt-walsheim-ultra-bold-oblique-web", normal, $file-formats: eot woff ttf svg);
