.tabs {
  position: relative;
  display: block;
  width: auto;
  float: left;
  background-color: $gray;
  height: emRhythm(8);
  padding: emRhythm(0.5);
  margin: 0 0 emRhythm(8);

  a {
    color: $red;

    @include transition(
      color .2s ease-in-out
    );
  }

  .tabs__tab {
    position: relative;
    height: emRhythm(7);
    padding: emRhythm(1.5) emRhythm(2);
    list-style-type: none;
    display: inline-block;
    background-color: $lightgray;

    @include transition(
      background-color .2s ease-in-out
    );

    &:hover {
      background-color: rgba($gray, 0.5);
      a { color: $white; }
    }

    &.is-active {
      background-color: $gray;
      a { color: $white; }
    }
  }
}

.tabs-content {
  visibility: hidden;
  opacity: 0;
  height: 0;
  display: none;

  @include transition(
    visibility .3s ease-in-out,
    opacity .3s ease-in-out
  );

  &.is-active {
    visibility: visible;
    opacity: 1;
    height: auto;
    display: block;
  }
}
