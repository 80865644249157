.title {
  text-align: center;
  padding-top: emRhythm(2*4);
  padding-bottom: emRhythm(2*4);
  margin-bottom: - emRhythm(0*4);

  padding-left: 0%;
  padding-right: 0%;

  @include breakpoint($tablet) {
    padding-left: 15%;
    padding-right: 15%;
  }

  @include breakpoint($screen) {
    padding-left: 30%;
    padding-right: 30%;
  }

  @include breakpoint($wide) {
    padding-left: 30%;
    padding-right: 30%;
  }

  &.title-long {
    background: linear-gradient($lightgray 50%, rgba(0,0,0,0) 50%);
  }

  &.title-short {
    background: linear-gradient($lightgray 50%, rgba(0,0,0,0) 50%);
  }
}
