.page {
  background-color: $white;
  min-height: 100%;
  margin-bottom: -$footer-height-mobile;

  @include breakpoint($tablet) { margin-bottom: -$footer-height-tablet; }
  @include breakpoint($screen) { margin-bottom: -$footer-height-screen; }

  &:after {
    content: "";
    display: block;
    height: $footer-height-mobile;
    @include breakpoint($tablet) { height: $footer-height-tablet; }
    @include breakpoint($screen) { height: $footer-height-screen; }
  }
}
