body {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-smooth: always !important;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004) !important;
  font-style: normal;
  font-weight: normal;
  color: $black;
}

::selection {
  background: $gray;
  color: $white;
 -webkit-text-stroke-width: 0;
}
::-moz-selection {
  background: $gray;
  color: $white;
}

h1 {
  @include setType(6, $ms-up2);
  font-family: "walsheim-bold";
  text-transform: uppercase;

  @include breakpoint($tablet) {
    @include setType(8, $ms-up3);
  }

  @include breakpoint($screen) {
    @include setType(3*4, $ms-up4);
    @include transform(rotate(-3deg));
  }
}

h2 {
  font-family: "walsheim-light";
  @include setType(1*5, $ms-up1);
  margin-bottom: emRhythm(2);
  letter-spacing: 0px;
}

h3 {
  font-family: "walsheim-regular";
  text-transform: uppercase;
  letter-spacing: 1px;
  @include setType(1*4, 1em);
  margin-bottom: 0;
}

h4 {
  font-family: "walsheim-regular";
  @include setType(1*4, 1em);
}

a {
  text-decoration: none;
  color: $red;
}

p {
  hyphens: none;
}

.text a {
  text-decoration: underline;
}
